import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["taskDetail"];

  showTask() {
    const clickedTaskDetail = this.taskDetailTarget;

    document.querySelectorAll('.line-task--show').forEach((taskDetail) => {
      if (taskDetail !== clickedTaskDetail) {
        taskDetail.classList.remove("line-task--show");
        taskDetail.classList.add("line-task--hidden");
      }
    });

    clickedTaskDetail.classList.toggle("line-task--hidden");
    clickedTaskDetail.classList.toggle("line-task--show");
  }
}
